import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../auth.service';
import { environment } from '../../environments/environment';

@Component({
    selector: 'app-toolbar',
    templateUrl: './toolbar.component.html',
})

export class ToolbarComponent implements OnInit {
    public environmentName: string;

    constructor(
        public authService: AuthService,
        public router: Router,
    ) { }

    ngOnInit(): void {
        this.environmentName = environment.environmentName;
    }

    public onLogin(): void {
        this.authService.startAuthentication();
    }

    public onLogout(): void {
        this.authService.clearAccessToken();
        this.authService.removeAuthenticatedUser().then(() => this.router.navigate(['']));
    }
}
