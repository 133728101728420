import { NgModule } from '@angular/core';
import { SharedModule } from '../shared.module';

import { OrganisationsComponent } from './organisations.component';
import { OrganisationComponent } from './organisation.component';
import { OrganisationApplicationsComponent } from './organisation-applications.component';

import { OrganisationsDataContext } from './organisations.datacontext';

@NgModule({
    // Import sub-modules here
    imports: [
        SharedModule,
    ],
    // Define all the components, directives and pipes, that are declared and used inside this module, here
    declarations: [
        OrganisationsComponent,
        OrganisationComponent,
        OrganisationApplicationsComponent,
    ],
    // Define any @Incectables, required by the module, here. Any subcomponents or modules can get the same instance of that @Injectable via dependency injection
    providers: [
        OrganisationsDataContext,
    ],
    // Define the components, directives or pipes we want to export here, so these components can be accessed from the outside
    exports: [
        OrganisationsComponent,
        OrganisationComponent,
        OrganisationApplicationsComponent,
    ],
})
export class OrganisationsModule { }
