import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class LoaderService {
    private numberOfRequests: number = 0;
    private loaderSubject: Subject<number> = new Subject<number>();
    public loaderState: Observable<number>;

    constructor() {
        this.loaderState = this.loaderSubject.asObservable();
    }

    show(): void {
        this.numberOfRequests++;
        this.loaderSubject.next(this.numberOfRequests);
    }

    hide(): void {
        this.numberOfRequests--;
        this.loaderSubject.next(this.numberOfRequests);
    }
}
