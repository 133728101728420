import { Component, OnInit } from '@angular/core';

import { AuthService } from '../auth.service';

@Component({
    templateUrl: './signin-callback-oidc.component.html',
})
export class SigninCallbackOidcComponent implements OnInit {

    constructor(
        private authService: AuthService,
    ) { }

    ngOnInit(): void {
        this.authService.completeAuthentication();
    }
}
