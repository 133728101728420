export class Organisation {
    OrganisationID: number;
    Code: string;
    Name: string;
    GatewayUrl: string;
    GatewayFallbackUrl: string;
    Guid: string;
    ForceFallbackRoute: boolean;
    AllowRetryOnFallbackRoute: boolean;
}

export class AddOrganisation {
    Code: string;
    Name: string;
    GatewayUrl: string;
    GatewayFallbackUrl: string;
    Guid: string;
    ForceFallbackRoute: boolean;
    AllowRetryOnFallbackRoute: boolean;
}
