import { NgModule } from '@angular/core';

import dayjs from 'dayjs';
dayjs.locale('nl');

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import { SharedModule } from './shared.module';

import { AccountModule } from './account/account.module';
import { ApplicationSettingsModule } from './applicationSettings/applicationSettings.module';
import { ApplicationsModule } from './applications/applications.module';
import { OrganisationsModule } from './organisations/organisations.module';

import { AppComponent } from './app.component';
import { LandingPageComponent } from './layout/landing-page.component';
import { LoaderComponent } from './layout/loader.component';
import { ToolbarComponent } from './layout/toolbar.component';

@NgModule({
    // Import sub-modules here
    imports: [
        BrowserAnimationsModule,
        HttpClientModule,

        AccountModule,
        ApplicationSettingsModule,
        ApplicationsModule,
        OrganisationsModule,

        SharedModule,
    ],
    // Define all the components, directives and pipes, that are declared and used inside this module, here
    declarations: [
        AppComponent,
        LandingPageComponent,
        LoaderComponent,
        ToolbarComponent,
    ],
    // Defines the root-component of the Application
    bootstrap: [AppComponent],
})
export class AppModule { }
