<div class="title-bar">
    <h2>Applicaties beheren</h2>
    <a mat-raised-button color="primary" routerLink="/applications/edit/0">Toevoegen</a>
</div>
<mat-divider></mat-divider>

<mat-form-field>
    <input matInput (keyup)="filterTable($event.target.value)" placeholder="Zoeken">
</mat-form-field>

<div class="mat-elevation-z8">
    <mat-table matSort [dataSource]="tableDataSource">
        <ng-container matColumnDef="ApplicationID">
            <mat-header-cell *matHeaderCellDef mat-sort-header> # </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.ApplicationID}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Name">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Naam </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.Name}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Guid">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Guid </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.Guid}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="DataServiceName">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Data service name </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.DataServiceName}} </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let application; columns: displayedColumns;" (click)="clickRow(application)" class="clickable-row"></mat-row>
    </mat-table>

    <mat-paginator [pageSizeOptions]="[10, 25, 50]"></mat-paginator>
</div>