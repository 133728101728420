import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

import { OrganisationsDataContext } from './organisations.datacontext';
import { Organisation } from './organisations.model';

@Component({
    templateUrl: './organisations.component.html',
    styleUrls: ['./organisations.component.scss'],
})
export class OrganisationsComponent implements OnInit {
    displayedColumns: string[] = ['OrganisationID', 'Code', 'Name', 'Guid', 'GatewayUrl', 'ForceFallbackRoute', 'AllowRetryOnFallbackRoute'];
    tableDataSource: MatTableDataSource<Organisation>;

    organisations: Organisation[];

    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    constructor(
        private router: Router,
        private organisationsDataContext: OrganisationsDataContext,
    ) { }

    ngOnInit(): void {
        this.refreshData();
    }

    filterTable(filterValue: string): void {
        this.tableDataSource.filter = filterValue.trim().toLowerCase();
    }

    clickRow(organisation: Organisation): void {
        this.router.navigate([`/organisations/edit/${organisation.OrganisationID}`]);
    }

    private refreshData(): void {
        this.organisationsDataContext.getAll()
            .subscribe((organisations: Organisation[]) => this.handleData(organisations));
    }

    private handleData(organisations: Organisation[]): void {
        this.organisations = organisations;
        this.tableDataSource = new MatTableDataSource(this.organisations);
        this.tableDataSource.sort = this.sort;
        this.tableDataSource.paginator = this.paginator;
    }

}
