export class SimpleDialogResult {
    OkResult: boolean;
    CancelResult: boolean;

    static createOkResult(): SimpleDialogResult {
        return { OkResult: true, CancelResult: false };
    }

    static createCancelResult(): SimpleDialogResult {
        return { OkResult: false, CancelResult: true };
    }
}
