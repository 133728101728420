import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './auth-guard.service';

import { LandingPageComponent } from './layout/landing-page.component';
import { SigninCallbackOidcComponent } from './account/signin-callback-oidc.component';

import { OrganisationsComponent } from './organisations/organisations.component';
import { OrganisationComponent } from './organisations/organisation.component';
import { ApplicationsComponent } from './applications/applications.component';
import { ApplicationComponent } from './applications/application.component';

const routes: Routes = [
    { path: '', pathMatch: 'full', component: LandingPageComponent },

    { path: 'organisations', component: OrganisationsComponent, canActivate: [AuthGuard] },
    { path: 'organisations/edit/:id', component: OrganisationComponent, canActivate: [AuthGuard] },

    { path: 'applications', component: ApplicationsComponent, canActivate: [AuthGuard] },
    { path: 'applications/edit/:id', component: ApplicationComponent, canActivate: [AuthGuard] },

    { path: 'signin-oidc', component: SigninCallbackOidcComponent },

    { path: '**', redirectTo: '' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule { }
