import { NgModule } from '@angular/core';

import { SigninCallbackOidcComponent } from './signin-callback-oidc.component';

@NgModule({
    // Define all the components, directives and pipes, that are declared and used inside this module, here
    declarations: [
        SigninCallbackOidcComponent,
    ],
    // Define the components, directives or pipes we want to export here, so these components can be accessed from the outside
    exports: [
        SigninCallbackOidcComponent,
    ],
})
export class AccountModule { }
