/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import { environment } from '../../environments/environment';

export abstract class Functions {

    public static isProductionMode(): boolean {
        return environment.production;
    }

    public static log(message: any): void {
        if (Functions.isProductionMode() == false)
            console.log(message);

    }
}
