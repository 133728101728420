import { NgModule } from '@angular/core';

import { ApplicationSettingsDataContext } from './applicationSettings.datacontext';

@NgModule({
    // Define any @Incectables, required by the module, here. Any subcomponents or modules can get the same instance of that @Injectable via dependency injection
    providers: [
        ApplicationSettingsDataContext,
    ],
})
export class ApplicationSettingsModule { }
