import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

type NotificationType = 'success' | 'error';

@Injectable()
export class NotificationService {
    private static NotificationDuration: number = 5000;
    private static NotificationDurationError: number = 8000;

    constructor(private snackBar: MatSnackBar) { }

    showSuccesNotification(notificationText: string): void {
        this.showNotification(notificationText, 'success', NotificationService.NotificationDuration);
    }

    showErrorNotification(notificationText: string): void {
        this.showNotification(notificationText, 'error', NotificationService.NotificationDurationError);
    }

    private showNotification(notificationText: string, type: NotificationType, notificationDuration: number): void {
        const config: MatSnackBarConfig = new MatSnackBarConfig();

        config.duration = notificationDuration;
        config.panelClass = [type];

        this.snackBar.open(notificationText, null, config);
    }
}
