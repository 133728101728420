import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { SimpleDialogResult } from '../common/base.models';

import { OrganisationsDataContext } from './organisations.datacontext';
import { Organisation, AddOrganisation } from './organisations.model';
import { ApplicationSetting } from '../applicationSettings/applicationSettings.model';
import { ConfirmationDialogComponent } from '../layout/confirmation-dialog.component';

@Component({
    templateUrl: './organisation.component.html',
})
export class OrganisationComponent implements OnInit {
    public organisationId: number;

    public organisation: Organisation;
    public applicationSettings: ApplicationSetting[];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private organisationsDataContext: OrganisationsDataContext,
        public dialog: MatDialog,
    ) { }

    ngOnInit(): void {
        this.route.params.subscribe((params) => {
            this.organisationId = +params['id'];

            this.organisation = new Organisation();
            this.organisation.OrganisationID = this.organisationId;

            this.refreshData();
        });
    }

    refreshData(): void {
        if (this.organisationId > 0) {
            this.organisationsDataContext.getById(this.organisationId)
                .subscribe((organisation: Organisation) => this.organisation = organisation);

            this.organisationsDataContext.getApplicationSettings(this.organisationId)
                .subscribe((applicationSettings: ApplicationSetting[]) => this.applicationSettings = applicationSettings);
        }
    }

    public onSave(): void {
        const postOrganisation: AddOrganisation = {
            Code: this.organisation.Code,
            Name: this.organisation.Name,
            GatewayUrl: this.organisation.GatewayUrl,
            GatewayFallbackUrl: this.organisation.GatewayFallbackUrl,
            Guid: this.organisation.Guid,
            ForceFallbackRoute: this.organisation.ForceFallbackRoute,
            AllowRetryOnFallbackRoute: this.organisation.AllowRetryOnFallbackRoute,
        };

        if (this.organisationId == 0) {
            this.organisationsDataContext.post(postOrganisation)
                .subscribe((organisation: Organisation) => this.router.navigate([`/organisations/edit/${organisation.OrganisationID}`]));
        } else {
            this.organisationsDataContext.put(this.organisation.OrganisationID, postOrganisation)
                .subscribe(() => this.refreshData());
        }
    }

    public onBlock(): void {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            data: {
                title: "Organisatie blokkeren",
                message: `Weet u zeker dat organisatie '${this.organisation.Name}' geblokkeerd mag worden?`,
            },
        });

        dialogRef.afterClosed().subscribe((dialogResult: SimpleDialogResult) => {
            if (dialogResult && dialogResult.OkResult) {
                this.organisationsDataContext.delete(this.organisation.OrganisationID)
                    .subscribe(() => this.router.navigate(['/organisations']));
            }
        });
    }
}
