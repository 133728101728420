import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';

import { ApplicationsDataContext } from './applications.datacontext';
import { Application } from './applications.model';

@Component({
    templateUrl: './applications.component.html',
})
export class ApplicationsComponent implements OnInit {
    displayedColumns: string[] = ['ApplicationID', 'Name', 'Guid', 'DataServiceName'];
    tableDataSource: MatTableDataSource<Application>;

    applications: Application[];

    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    constructor(
        private router: Router,
        private applicationsDataContext: ApplicationsDataContext,
    ) { }

    ngOnInit(): void {
        this.refreshData();
    }

    filterTable(filterValue: string): void {
        this.tableDataSource.filter = filterValue.trim().toLowerCase();
    }

    clickRow(application: Application): void {
        this.router.navigate([`/applications/edit/${application.ApplicationID}`]);
    }

    private refreshData(): void {
        this.applicationsDataContext.getAll()
            .subscribe((applications: Application[]) => this.handleData(applications));
    }

    private handleData(applications: Application[]): void {
        this.applications = applications;
        this.tableDataSource = new MatTableDataSource(this.applications);
        this.tableDataSource.sort = this.sort;
        this.tableDataSource.paginator = this.paginator;
    }
}
