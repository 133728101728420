import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SimpleDialogResult } from '../common/base.models';

export interface ConfirmationDialogData {
    title: string;
    message: string;
    onConfirm: () => void;
}

@Component({
    templateUrl: './confirmation-dialog.component.html',
})
export class ConfirmationDialogComponent {

    constructor(public dialogRef: MatDialogRef<ConfirmationDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData) { }

    public onConfirm(): void {
        this.dialogRef.close(SimpleDialogResult.createOkResult());
    }

    public onCancel(): void {
        this.dialogRef.close(SimpleDialogResult.createCancelResult());
    }
}
