<h3>Gekoppelde applicaties</h3>

<mat-card class="container">
    <div class="flex-row">
        <mat-form-field class="flex-auto">
            <mat-label>Applicaties</mat-label>
            <mat-select [(value)]="selectedApplicationID">
                @for (application of applications; track application.ApplicationID) {
                    <mat-option [value]="application.ApplicationID">
                        {{application.Name}}
                    </mat-option>
                }
            </mat-select>
        </mat-form-field>
        <button mat-raised-button color="primary" (click)="onAddApplicationSetting()" class="btn-margin-left">Toevoegen</button>
    </div>

    <mat-action-list>
        @for (applicationSetting of applicationSettings; track applicationSetting.ApplicationSettingID) {
            <mat-list-item>
                <h3 matListItemTitle>{{applicationSetting.ApplicationName}}</h3>

                <button matListItemMeta mat-icon-button (click)="onDeleteApplicationSetting(applicationSetting)">
                    <mat-icon>delete</mat-icon>
                </button>
            </mat-list-item>
        }
    </mat-action-list>
</mat-card>