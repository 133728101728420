import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../auth.service';

@Component({
    templateUrl: './landing-page.component.html',
})
export class LandingPageComponent implements OnInit {
    constructor(
        private router: Router,
        private authService: AuthService,
    ) { }

    ngOnInit(): void {
        if (this.authService.isLoggedIn)
            this.router.navigate(['organisations']);
    }

    public onLogin(): void {
        this.authService.startAuthentication();
    }
}
