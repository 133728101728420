export class AppStorage {
    static get<T>(key: string): T {
        const valueAsJson = localStorage.getItem(key);
        return <T>JSON.parse(valueAsJson);
    }

    static getOrDefault<T>(key: string, defaultValue: T): T {
        const valueAsJson = localStorage.getItem(key);

        if (!valueAsJson || valueAsJson.length === 0)
            return defaultValue;

        return <T>JSON.parse(valueAsJson);
    }

    static set<T>(key: string, value: T): void {
        const valueAsJson = JSON.stringify(value);
        localStorage.setItem(key, valueAsJson);
    }

    static clear(key: string): void {
        localStorage.removeItem(key);
    }
}
