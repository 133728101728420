<div class="landingspagina">
    <div class="mat-elevation-z8 container">
        <h1>GreenCommerce - Connector Beheerportal</h1>

        <p>
            Deze GreenCommerce Connector fungeert als gateway tussen de online GreenCommerce services en lokale omgevingen.
            <br />Diverse client applicaties maken via deze connector gebruik van de backend API's mits er met access tokens wordt geauthenticeerd die van de <a href="https://identity.jem-id.nl/">JEM-id identity server</a> afkomstig zijn.
        </p>

        <p>Bezoek voor meer informatie: <a href="https://www.greencommerce.nl/">www.greencommerce.nl</a></p>

        <button mat-raised-button color="primary" (click)="onLogin()">Inloggen</button>
    </div>
</div>
