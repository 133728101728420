export class Application {
    ApplicationID: number;
    Name: string;
    Guid: string;
    DataServiceName: string;
}

export class AddApplication {
    Name: string;
    Guid: string;
    DataServiceName: string;
}
