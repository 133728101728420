import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseDataContext, IDataContext } from '../common/base.datacontext';

import { Organisation, AddOrganisation } from './organisations.model';
import { ApplicationSetting } from '../applicationSettings/applicationSettings.model';

@Injectable()
export class OrganisationsDataContext implements IDataContext {

    /**
     * BaseURL for the controller: 'organisations'
     */
    baseUrl: string = 'organisations';

    constructor(private dataContext: BaseDataContext) { }

    public getAll(): Observable<Organisation[]> {
        return this.dataContext.getAndMap<Organisation[]>(this.baseUrl);
    }

    public getById(id: number): Observable<Organisation> {
        return this.dataContext.getAndMap<Organisation>(`${this.baseUrl}/${id}`);
    }

    public getApplicationSettings(id: number): Observable<ApplicationSetting[]> {
        return this.dataContext.getAndMap<ApplicationSetting[]>(`${this.baseUrl}/${id}/applicationsettings`);
    }

    public post(postModel: AddOrganisation): Observable<Organisation> {
        return this.dataContext.postAndMap<Organisation>(`${this.baseUrl}`, postModel, "Organisatie is toegevoegd");
    }

    public put(id: number, postModel: AddOrganisation): Observable<Organisation> {
        return this.dataContext.putAndMap<Organisation>(`${this.baseUrl}/${id}`, postModel, "Organisatie is opgeslagen");
    }

    public delete(id: number): Observable<object> {
        return this.dataContext.delete(`${this.baseUrl}/${id}`, "Organisatie is geblokkeerd");
    }
}
