import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { SimpleDialogResult } from '../common/base.models';

import { ApplicationSettingsDataContext } from '../applicationSettings/applicationSettings.datacontext';
import { ApplicationsDataContext } from '../applications/applications.datacontext';
import { ApplicationSetting, AddApplicationSetting } from '../applicationSettings/applicationSettings.model';
import { Application } from '../applications/applications.model';
import { ConfirmationDialogComponent } from '../layout/confirmation-dialog.component';
import { orderBy } from 'lodash-es';

@Component({
    templateUrl: './organisation-applications.component.html',
    selector: 'app-organisation-applications',
})
export class OrganisationApplicationsComponent implements OnInit {
    public applications: Application[];
    public selectedApplicationID: number;

    @Input() organisationId: number;
    @Input() applicationSettings: ApplicationSetting[];

    @Output() applicationAdded = new EventEmitter();
    @Output() applicationRemoved = new EventEmitter();

    constructor(
        private applicationSettingsDataContext: ApplicationSettingsDataContext,
        private applicationsDataContext: ApplicationsDataContext,
        public dialog: MatDialog,
    ) { }

    ngOnInit(): void {
        this.applicationsDataContext.getAll()
            .subscribe((applications: Application[]) => this.applications = orderBy(applications, (a) => a.Name));
    }

    onAddApplicationSetting(): void {
        const postApplicationSetting: AddApplicationSetting = {
            ApplicationID: this.selectedApplicationID,
            OrganisationID: this.organisationId,
        };

        this.applicationSettingsDataContext.post(postApplicationSetting)
            .subscribe(() => {
                this.selectedApplicationID = 0;
                this.applicationAdded.next(null);
            });
    }

    onDeleteApplicationSetting(applicationSetting: ApplicationSetting): void {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            data: {
                title: "Applicatie verwijderen uit organisatie",
                message: `Weet u zeker dat '${applicationSetting.ApplicationName}' uit de organisatie verwijderd mag worden?`,
            },
        });

        dialogRef.afterClosed().subscribe((dialogResult: SimpleDialogResult) => {
            if (dialogResult && dialogResult.OkResult) {
                this.applicationSettingsDataContext.delete(applicationSetting.ApplicationSettingID)
                    .subscribe(() => this.applicationRemoved.next(null));
            }
        });
    }
}
