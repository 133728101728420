/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, tap, timeout } from 'rxjs/operators';
import dayjs from 'dayjs';

import { AuthService } from '../auth.service';
import { LoaderService } from '../layout/loader.service';
import { NotificationService } from './notification.service';
import { environment } from '../../environments/environment';
import { Functions } from '../common/functions';

export interface IDataContext {
    baseUrl: string;
}

@Injectable()
export class BaseDataContext {
    constructor(
        private http: HttpClient,
        private router: Router,
        private authService: AuthService,
        private notificationService: NotificationService,
        private loaderService: LoaderService,
    ) { }

    /** Default timeout as 15 seconds. */
    private static readonly defaultTimeOut: number = 15000;

    getAndMap<T>(url: string, queryParameters: any = null, successMessage: string = '', handleError: boolean = true): Observable<T> {
        this.onBeginHttpRequest();
        return this.http.get<T>(environment.gcConnectorUrl + url, this.getRequestOptions(queryParameters))
            .pipe(
                timeout(BaseDataContext.defaultTimeOut),
                tap(() => this.doSuccess(url, successMessage)),
                catchError((e) => this.handleError(url, e, handleError)),
                finalize(() => this.onEndHttpRequest()),
            );
    }

    postAndMap<T>(url: string, obj: any, successMessage: string = ''): Observable<T> {
        this.onBeginHttpRequest();
        return this.http.post<T>(environment.gcConnectorUrl + url, JSON.stringify(obj), this.getRequestOptions())
            .pipe(
                timeout(BaseDataContext.defaultTimeOut),
                tap(() => this.doSuccess(url, successMessage)),
                catchError((e) => this.handleError(url, e)),
                finalize(() => this.onEndHttpRequest()),
            );
    }

    post(url: string, obj: any, successMessage: string = ''): Observable<object> {
        this.onBeginHttpRequest();
        console.log(JSON.stringify(obj));
        return this.http.post(environment.gcConnectorUrl + url, JSON.stringify(obj), this.getRequestOptions())
            .pipe(
                timeout(BaseDataContext.defaultTimeOut),
                tap(() => this.doSuccess(url, successMessage)),
                catchError((e) => this.handleError(url, e)),
                finalize(() => this.onEndHttpRequest()),
            );
    }

    put(url: string, obj: any, successMessage: string = ''): Observable<object> {
        this.onBeginHttpRequest();
        return this.http.put(environment.gcConnectorUrl + url, JSON.stringify(obj), this.getRequestOptions())
            .pipe(
                timeout(BaseDataContext.defaultTimeOut),
                tap(() => this.doSuccess(url, successMessage)),
                catchError((e) => this.handleError(url, e)),
                finalize(() => this.onEndHttpRequest()),
            );
    }

    putAndMap<T>(url: string, obj: any, successMessage: string = ''): Observable<T> {
        this.onBeginHttpRequest();
        return this.http.put<T>(environment.gcConnectorUrl + url, JSON.stringify(obj), this.getRequestOptions())
            .pipe(
                timeout(BaseDataContext.defaultTimeOut),
                tap(() => this.doSuccess(url, successMessage)),
                catchError((e) => this.handleError(url, e)),
                finalize(() => this.onEndHttpRequest()),
            );
    }

    patchAndMap<T>(url: string, successMessage: string = ''): Observable<T> {
        this.onBeginHttpRequest();
        return this.http.patch<T>(environment.gcConnectorUrl + url, null, this.getRequestOptions())
            .pipe(
                timeout(BaseDataContext.defaultTimeOut),
                tap(() => this.doSuccess(url, successMessage)),
                catchError((e) => this.handleError(url, e)),
                finalize(() => this.onEndHttpRequest()),
            );
    }

    patch(url: string, successMessage: string = ''): Observable<object> {
        this.onBeginHttpRequest();
        return this.http.patch(environment.gcConnectorUrl + url, null, this.getRequestOptions())
            .pipe(
                timeout(BaseDataContext.defaultTimeOut),
                tap(() => this.doSuccess(url, successMessage)),
                catchError((e) => this.handleError(url, e)),
                finalize(() => this.onEndHttpRequest()),
            );
    }

    delete(url: string, successMessage: string = ''): Observable<object> {
        this.onBeginHttpRequest();
        return this.http.delete(environment.gcConnectorUrl + url, this.getRequestOptions())
            .pipe(
                timeout(BaseDataContext.defaultTimeOut),
                tap(() => this.doSuccess(url, successMessage)),
                catchError((e) => this.handleError(url, e)),
                finalize(() => this.onEndHttpRequest()),
            );
    }

    private getRequestOptions(param: any = null): { headers: HttpHeaders, params: HttpParams; } {
        const httpHeaders: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Authorization', 'Bearer ' + this.authService.accessToken);

        const paramsObject: any = {};

        if (param != null) {
            Object.keys(param).forEach((key) => {
                let val = param[key];

                if (!val)
                    return;

                if (val instanceof Date)
                    val = dayjs(val).format('YYYY-MM-DD');

                paramsObject[key] = val;
            });
        }

        return { headers: httpHeaders, params: new HttpParams({ fromObject: paramsObject }) };
    }

    private doSuccess(url: string, succesMelding: string = ''): void {
        Functions.log('Succes: ' + url + ' ' + succesMelding);

        if (succesMelding != null && succesMelding.length > 0)
            this.notificationService.showSuccesNotification(succesMelding);
    }

    private handleError(url: string, errorResponse: HttpErrorResponse, handleError: boolean = true): Observable<never> {
        this.outputResult(url, errorResponse);

        let message: string;
        if (errorResponse.error && errorResponse.error.ExceptionMessage)
            message = errorResponse.error.ExceptionMessage;
        else if (errorResponse.error)
            message = errorResponse.error.Message;

        if (!message || message.length == 0)
            message = errorResponse.error;
        if (!message || message.length == 0)
            message = errorResponse.message;

        Functions.log('Error: ' + url + ' ' + message);

        if ([401, 403].indexOf(errorResponse.status) > -1) {
            this.authService.clearAccessToken();
            this.authService.removeAuthenticatedUser().then(() => this.router.navigate(['']));
        }

        if (handleError) {
            if (message && message.length > 0)
                this.notificationService.showErrorNotification(message);
            else
                this.notificationService.showErrorNotification('Er is iets fout gegaan bij het aanroepen van de applicatie...');
        }

        return throwError(message || 'Server error');
    }

    private onBeginHttpRequest(): void {
        this.loaderService.show();
    }

    private onEndHttpRequest(): void {
        this.loaderService.hide();
    }

    private outputResult<T>(url: string, result: T): void {
        if (location.href.toLowerCase().indexOf('localhost') > -1) {
            const apiUrl = '/' + url;
            Functions.log({ apiUrl, result });
        }
    }
}
