import { NgModule } from '@angular/core';

import { AuthGuard } from './auth-guard.service';
import { AuthService } from './auth.service';
import { NotificationService } from './common/notification.service';
import { LoaderService } from './layout/loader.service';
import { BaseDataContext } from './common/base.datacontext';

import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from './material.module';

import { ConfirmationDialogComponent } from './layout/confirmation-dialog.component';

@NgModule({
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        MaterialModule,
    ],
    // Define all the components, directives and pipes, that are declared and used inside this module, here
    declarations: [
        ConfirmationDialogComponent,
    ],
    // Define any @Incectables, required by the module, here. Any subcomponents or modules can get the same instance of that @Injectable via dependency injection
    providers: [
        AuthGuard,
        AuthService,
        NotificationService,
        LoaderService,
        BaseDataContext,
    ],
    exports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        MaterialModule,
    ],
    schemas: [],
})
export class SharedModule { }