<div class="title-bar">
    <h2>Organisaties beheren</h2>
    <a mat-raised-button color="primary" routerLink="/organisations/edit/0">Toevoegen</a>
</div>
<mat-divider></mat-divider>

<mat-form-field>
    <input matInput (keyup)="filterTable($event.target.value)" placeholder="Zoeken">
</mat-form-field>

<div class="mat-elevation-z8">
    <mat-table matSort [dataSource]="tableDataSource">
        <ng-container matColumnDef="OrganisationID">
            <mat-header-cell *matHeaderCellDef mat-sort-header> # </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.OrganisationID}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Code">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Code </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.Code}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Name">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Naam </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.Name}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="Guid">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Guid </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.Guid}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="GatewayUrl">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Gateway url </mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.GatewayUrl}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="ForceFallbackRoute">
            <mat-header-cell *matHeaderCellDef mat-sort-header class="fallback-route"> Fallback </mat-header-cell>
            <mat-cell *matCellDef="let element" class="fallback-route"> {{element.ForceFallbackRoute ? "Actief" : ""}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="AllowRetryOnFallbackRoute">
            <mat-header-cell *matHeaderCellDef mat-sort-header class="fallback-route"> Retry </mat-header-cell>
            <mat-cell *matCellDef="let element" class="fallback-route"> {{element.AllowRetryOnFallbackRoute ? "Actief" : ""}} </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let organisation; columns: displayedColumns;" (click)="clickRow(organisation)" class="clickable-row"></mat-row>
    </mat-table>

    <mat-paginator [pageSizeOptions]="[10, 25, 50]"></mat-paginator>
</div>
