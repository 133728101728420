import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseDataContext, IDataContext } from '../common/base.datacontext';

import { Application, AddApplication } from './applications.model';
import { ApplicationSetting } from '../applicationSettings/applicationSettings.model';

@Injectable()
export class ApplicationsDataContext implements IDataContext {

    /**
     * BaseURL for the controller: 'applications'
     */
    baseUrl: string = 'applications';

    constructor(private dataContext: BaseDataContext) { }

    public getAll(): Observable<Application[]> {
        return this.dataContext.getAndMap<Application[]>(this.baseUrl);
    }

    public getById(id: number): Observable<Application> {
        return this.dataContext.getAndMap<Application>(`${this.baseUrl}/${id}`);
    }

    public getApplicationSettings(id: number): Observable<ApplicationSetting[]> {
        return this.dataContext.getAndMap<ApplicationSetting[]>(`${this.baseUrl}/${id}/applicationsettings`);
    }

    public post(postModel: AddApplication): Observable<Application> {
        return this.dataContext.postAndMap<Application>(`${this.baseUrl}`, postModel, "Applicatie is toegevoegd");
    }

    public put(id: number, postModel: AddApplication): Observable<Application> {
        return this.dataContext.putAndMap<Application>(`${this.baseUrl}/${id}`, postModel, "Applicatie is opgeslagen");
    }

    public delete(id: number): Observable<object> {
        return this.dataContext.delete(`${this.baseUrl}/${id}`, "Applicatie is geblokkeerd");
    }
}
