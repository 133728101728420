import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { LoaderService } from './loader.service';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
})
export class LoaderComponent implements OnInit, OnDestroy {
    public show: boolean = false;
    private loaderSubscription: Subscription;

    constructor(private loaderService: LoaderService) { }

    ngOnInit(): void {
        this.loaderSubscription = this.loaderService.loaderState
            .subscribe((requests: number) => {
                if (requests > 0)
                    this.show = true;
                else
                    this.show = false;
            });
    }

    ngOnDestroy(): void {
        if (this.loaderSubscription)
            this.loaderSubscription.unsubscribe();
    }
}
