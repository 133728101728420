import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { SimpleDialogResult } from '../common/base.models';

import { ApplicationsDataContext } from './applications.datacontext';
import { Application, AddApplication } from './applications.model';
import { ApplicationSetting } from '../applicationSettings/applicationSettings.model';
import { ConfirmationDialogComponent } from '../layout/confirmation-dialog.component';

@Component({
    templateUrl: './application.component.html',
})
export class ApplicationComponent implements OnInit {
    applicationId: number;

    application: Application;
    applicationSettings: ApplicationSetting[];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private applicationsDataContext: ApplicationsDataContext,
        public dialog: MatDialog,
    ) { }

    ngOnInit(): void {
        this.route.params.subscribe((params) => {
            this.applicationId = +params['id'];

            this.application = new Application();
            this.application.ApplicationID = this.applicationId;

            this.refreshData();
        });
    }

    refreshData(): void {
        if (this.applicationId > 0) {
            this.applicationsDataContext.getById(this.applicationId)
                .subscribe((application: Application) => this.application = application);

            this.applicationsDataContext.getApplicationSettings(this.applicationId)
                .subscribe((applicationSettings: ApplicationSetting[]) => this.applicationSettings = applicationSettings);
        }
    }

    onSave(): void {
        const postApplication: AddApplication = {
            Name: this.application.Name,
            Guid: this.application.Guid,
            DataServiceName: this.application.DataServiceName,
        };

        if (this.applicationId == 0) {
            this.applicationsDataContext.post(postApplication)
                .subscribe((application: Application) => this.router.navigate([`/applications/edit/${application.ApplicationID}`]));
        } else {
            this.applicationsDataContext.put(this.application.ApplicationID, postApplication)
                .subscribe(() => this.refreshData());
        }
    }

    onBlock(): void {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            data: {
                title: "Applicatie blokkeren",
                message: `Weet u zeker dat applicatie '${this.application.Name}' geblokkeerd mag worden?`,
            },
        });

        dialogRef.afterClosed().subscribe((dialogResult: SimpleDialogResult) => {
            if (dialogResult && dialogResult.OkResult) {
                this.applicationsDataContext.delete(this.application.ApplicationID)
                    .subscribe(() => this.router.navigate(['/applications']));
            }
        });
    }
}
