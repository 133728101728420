<h1 mat-dialog-title>{{data.title}}</h1>

<mat-dialog-content>
    {{data.message}}
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-stroked-button (click)="onConfirm()">Ja</button>
    <button mat-stroked-button (click)="onCancel()">Nee</button>
</mat-dialog-actions>
