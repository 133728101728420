import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { orderBy } from 'lodash-es';

import { SimpleDialogResult } from '../common/base.models';

import { ApplicationSettingsDataContext } from '../applicationSettings/applicationSettings.datacontext';
import { OrganisationsDataContext } from '../organisations/organisations.datacontext';
import { ApplicationSetting, AddApplicationSetting } from '../applicationSettings/applicationSettings.model';
import { Organisation } from '../organisations/organisations.model';
import { ConfirmationDialogComponent } from '../layout/confirmation-dialog.component';

@Component({
    selector: 'app-application-organisations',
    templateUrl: './application-organisations.component.html',
})
export class ApplicationOrganisationsComponent implements OnInit {
    public organisations: Organisation[];
    public selectedOrganisationID: number;

    @Input() applicationId: number;
    @Input() applicationSettings: ApplicationSetting[];

    @Output() organisationAdded = new EventEmitter();
    @Output() organisationRemoved = new EventEmitter();

    constructor(
        private applicationSettingsDataContext: ApplicationSettingsDataContext,
        private organisationsDataContext: OrganisationsDataContext,
        public dialog: MatDialog,
    ) { }

    ngOnInit(): void {
        this.organisationsDataContext.getAll()
            .subscribe((organisations: Organisation[]) => this.organisations = orderBy(organisations, (o) => o.Name));
    }

    onAddApplicationSetting(): void {
        const postApplicationSetting: AddApplicationSetting = {
            ApplicationID: this.applicationId,
            OrganisationID: this.selectedOrganisationID,
        };

        this.applicationSettingsDataContext.post(postApplicationSetting)
            .subscribe(() => {
                this.selectedOrganisationID = 0;
                this.organisationAdded.next(null);
            });
    }

    onDeleteApplicationSetting(applicationSetting: ApplicationSetting): void {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            data: {
                title: "Organisatie verwijderen uit applicatie",
                message: `Weet u zeker dat '${applicationSetting.OrganisationName}' uit de applicatie verwijderd mag worden?`,
            },
        });

        dialogRef.afterClosed().subscribe((dialogResult: SimpleDialogResult) => {
            if (dialogResult && dialogResult.OkResult) {
                this.applicationSettingsDataContext.delete(applicationSetting.ApplicationSettingID)
                    .subscribe(() => this.organisationRemoved.next(null));
            }
        });
    }
}
