import { Injectable } from '@angular/core';
import { BaseDataContext, IDataContext } from '../common/base.datacontext';
import { AddApplicationSetting } from './applicationSettings.model';
import { Observable } from 'rxjs';

@Injectable()
export class ApplicationSettingsDataContext implements IDataContext {

    /**
     * BaseURL for the controller: 'applicationSettings'
     */
    baseUrl: string = 'applicationSettings';

    constructor(private dataContext: BaseDataContext) { }

    post(postModel: AddApplicationSetting): Observable<object> {
        return this.dataContext.post(this.baseUrl, postModel, "Applicatie is toegevoegd aan de organisatie");
    }

    delete(id: number): Observable<object> {
        return this.dataContext.delete(`${this.baseUrl}/${id}`, "Applicatie is verwijderd uit de organisatie");
    }
}