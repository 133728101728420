import { NgModule } from '@angular/core';
import { SharedModule } from '../shared.module';

import { ApplicationsComponent } from './applications.component';
import { ApplicationComponent } from './application.component';
import { ApplicationOrganisationsComponent } from './application-organisations.component';

import { ApplicationsDataContext } from './applications.datacontext';

@NgModule({
    // Import sub-modules here
    imports: [
        SharedModule,
    ],
    // Define all the components, directives and pipes, that are declared and used inside this module, here
    declarations: [
        ApplicationsComponent,
        ApplicationComponent,
        ApplicationOrganisationsComponent,
    ],
    // Define any @Incectables, required by the module, here. Any subcomponents or modules can get the same instance of that @Injectable via dependency injection
    providers: [
        ApplicationsDataContext,
    ],
    // Define the components, directives or pipes we want to export here, so these components can be accessed from the outside
    exports: [
        ApplicationsComponent,
        ApplicationComponent,
        ApplicationOrganisationsComponent,
    ],
})
export class ApplicationsModule { }
