<h3>Gekoppelde organisaties</h3>

<mat-card class="container">
    <div class="flex-row">
        <mat-form-field class="flex-auto">
            <mat-label>Organisaties</mat-label>
            <mat-select [(value)]="selectedOrganisationID">
                @for (organisation of organisations; track organisation.OrganisationID) {
                    <mat-option [value]="organisation.OrganisationID">
                        {{organisation.Name}}
                    </mat-option>
                }
            </mat-select>
        </mat-form-field>
        <button mat-raised-button color="primary" (click)="onAddApplicationSetting()" class="btn-margin-left">Toevoegen</button>
    </div>

    <mat-action-list>
        @for (applicationSetting of applicationSettings; track applicationSetting.ApplicationSettingID) {
            <mat-list-item>
                <h3 matListItemTitle>{{applicationSetting.OrganisationName}}</h3>
                
                <button matListItemMeta mat-icon-button (click)="onDeleteApplicationSetting(applicationSetting)">
                    <mat-icon>delete</mat-icon>
                </button>
            </mat-list-item>
        }
    </mat-action-list>
</mat-card>