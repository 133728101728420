<div class="title-bar">
    <h2>Organisatie gegevens bewerken</h2>
    @if (organisationId > 0) {
        <a mat-raised-button color="primary" (click)="onBlock()">Blokkeren</a>
    }
</div>

<mat-card class="container">
    <div class="flex-column">
        <mat-form-field>
            <mat-label>Code</mat-label>
            <input matInput placeholder="Code" name="Code" [(ngModel)]="organisation.Code">
        </mat-form-field>

        <mat-form-field>
            <mat-label>Naam</mat-label>
            <input matInput placeholder="Naam" name="Name" [(ngModel)]="organisation.Name">
        </mat-form-field>

        <mat-form-field>
            <mat-label>Gateway URL</mat-label>
            <input matInput placeholder="Gateway URL" name="Gateway Url" [(ngModel)]="organisation.GatewayUrl">
        </mat-form-field>

        <mat-form-field>
            <mat-label>Gateway Fallback URL</mat-label>
            <input matInput placeholder="" name="Gateway Fallback URL" [(ngModel)]="organisation.GatewayFallbackUrl">
        </mat-form-field>

        <mat-form-field>
            <mat-label>Guid</mat-label>
            <input matInput placeholder="Guid" name="Guid" [(ngModel)]="organisation.Guid">
        </mat-form-field>

        <div class="form-field" style="padding-bottom: 1.25em;">
            <mat-checkbox name="forceFallback" [(ngModel)]="organisation.ForceFallbackRoute" [disabled]="organisation.GatewayFallbackUrl?.length < 11">Forceer fallback configuratie</mat-checkbox>
        </div>

        <div class="form-field" style="padding-bottom: 1.25em;">
            <mat-checkbox name="retryFallback" [(ngModel)]="organisation.AllowRetryOnFallbackRoute" [disabled]="organisation.GatewayFallbackUrl?.length < 11">Retry policy op fallback configuratie</mat-checkbox>
        </div>
    </div>
    <div><button mat-raised-button color="primary" (click)="onSave()">Opslaan</button></div>
</mat-card>
<br />

@if (organisationId > 0) {
    <app-organisation-applications
        [organisationId]="organisationId" 
        [applicationSettings]="applicationSettings"
        (applicationAdded)="refreshData()"
        (applicationRemoved)="refreshData()"
    >
    </app-organisation-applications>
}