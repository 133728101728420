<div class="title-bar">
    <h2>Applicatie gegevens bewerken</h2>
    @if (applicationId > 0) {
        <a mat-raised-button color="primary" (click)="onBlock()">Blokkeren</a>
    }
</div>

<mat-card class="container">
    <div class="flex-column">
        <mat-form-field>
            <mat-label>Naam</mat-label>
            <input matInput placeholder="Naam" name="Name" [(ngModel)]="application.Name">
        </mat-form-field>

        <mat-form-field>
            <mat-label>Guid</mat-label>
            <input matInput placeholder="Guid" name="Guid" [(ngModel)]="application.Guid">
        </mat-form-field>

        <mat-form-field>
            <mat-label>Data serivce</mat-label>
            <input matInput placeholder="DataServiceName" name="DataServiceName" [(ngModel)]="application.DataServiceName">
        </mat-form-field>
    </div>

    <div><button mat-raised-button color="primary" (click)="onSave()">Opslaan</button></div>
</mat-card>
<br />

@if (applicationId > 0) {
    <app-application-organisations
        [applicationId]="applicationId"
        [applicationSettings]="applicationSettings"
        (organisationAdded)="refreshData()"
        (organisationRemoved)="refreshData()">
    </app-application-organisations>
}